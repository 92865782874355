import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "subtotal", "tax", "taxRate", "shipping", "total",
    "totalCost", "totalProfit", "marginPercentage", "marginIndicator",
    "discount", "shippingDiscount"
  ]

  initialize() {
    this.updateTotals = this.updateTotals.bind(this)
    this.handleTaxRateChange = this.handleTaxRateChange.bind(this)
    this.handleShippingChange = this.handleShippingChange.bind(this)
    this.handleDiscountChange = this.handleDiscountChange.bind(this)
    this.handleShippingDiscountChange = this.handleShippingDiscountChange.bind(this)
  }

  connect() {
    // Order item updates
    document.addEventListener('order-item:update', this.updateTotals)
    document.addEventListener('discount:changed', this.updateTotals)

    // Add separate handlers for discount and shipping discount fields
    const discountFields = [
      { selector: 'input[name="order[discount_amount]"]', event: ['input', 'change'] },
      { selector: 'select[name="order[discount_type]"]', event: ['change'] },
      { selector: 'input[name="order[shipping_discount_amount]"]', event: ['input', 'change'] },
      { selector: 'select[name="order[shipping_discount_type]"]', event: ['change'] },
    ]

    discountFields.forEach(({ selector, event }) => {
      const element = document.querySelector(selector)
      if (element) {
        event.forEach(evt => element.addEventListener(evt, this.updateTotals))
      }
    })

    // Regular fields (tax, shipping)
    const otherFields = [
      'input[name="order[tax_rate]"]',
      'input[name="order[shipping_cost]"]',
    ]

    otherFields.forEach(selector => {
      const element = document.querySelector(selector)
      if (element) {
        element.addEventListener('change', this.updateTotals)
      }
    })

    // Initial calculation
    this.updateTotals()
  }

  disconnect() {
    // Remove all event listeners
    document.removeEventListener('order-item:update', this.updateTotals)
    document.removeEventListener('discount:changed', this.updateTotals)

    const discountFields = [
      { selector: 'input[name="order[discount_amount]"]', event: ['input', 'change'] },
      { selector: 'select[name="order[discount_type]"]', event: ['change'] },
      { selector: 'input[name="order[shipping_discount_amount]"]', event: ['input', 'change'] },
      { selector: 'select[name="order[shipping_discount_type]"]', event: ['change'] },
    ]

    discountFields.forEach(({ selector, event }) => {
      const element = document.querySelector(selector)
      if (element) {
        event.forEach(evt => element.removeEventListener(evt, this.updateTotals))
      }
    })

    const otherFields = [
      'input[name="order[tax_rate]"]',
      'input[name="order[shipping_cost]"]',
    ]

    otherFields.forEach(selector => {
      const element = document.querySelector(selector)
      if (element) {
        element.removeEventListener('change', this.updateTotals)
      }
    })
  }

  calculateLineItemDiscount(itemElement) {
    const quantity = parseFloat(itemElement.querySelector('[data-order-item-target="quantity"]').value) || 0
    const price = parseFloat(itemElement.querySelector('[data-order-item-target="price"]').value) || 0
    const discountType = itemElement.querySelector('[data-order-item-target="discountType"]')?.value
    const discountAmount = parseFloat(itemElement.querySelector('[data-order-item-target="discountAmount"]')?.value || 0)

    const itemSubtotal = quantity * price
    return discountType === 'percentage'
      ? itemSubtotal * (discountAmount / 100)
      : Math.min(discountAmount, itemSubtotal) // Cannot discount more than the subtotal
  }

  updateTotals() {
    let subtotal = 0
    let totalCost = 0
    let lineItemDiscounts = 0

    // Calculate totals from all order items
    document.querySelectorAll('[data-controller="order-item"]').forEach(item => {
      const quantity = parseFloat(item.querySelector('[data-order-item-target="quantity"]').value) || 0
      const price = parseFloat(item.querySelector('[data-order-item-target="price"]').value) || 0
      const cost = parseFloat(item.querySelector('[data-order-item-target="cost"]').value) || 0

      const itemSubtotal = quantity * price
      const itemDiscount = this.calculateLineItemDiscount(item)

      subtotal += itemSubtotal
      totalCost += quantity * cost
      lineItemDiscounts += itemDiscount
    })

    // Get order-level discounts
    const orderDiscountType = document.querySelector('select[name="order[discount_type]"]')?.value
    const orderDiscountAmount = parseFloat(document.querySelector('input[name="order[discount_amount]"]')?.value || 0)
    const orderDiscount = orderDiscountType === 'percentage'
      ? subtotal * (orderDiscountAmount / 100)
      : Math.min(orderDiscountAmount, subtotal)

    // Calculate shipping and its discount
    const shipping = parseFloat(document.querySelector('input[name="order[shipping_cost]"]')?.value || 0)
    const shippingDiscountType = document.querySelector('select[name="order[shipping_discount_type]"]')?.value
    const shippingDiscountAmount = parseFloat(document.querySelector('input[name="order[shipping_discount_amount]"]')?.value || 0)
    const shippingDiscount = shippingDiscountType === 'percentage'
      ? shipping * (shippingDiscountAmount / 100)
      : Math.min(shippingDiscountAmount, shipping)

    // Calculate final totals
    const totalDiscounts = lineItemDiscounts + orderDiscount
    const totalDiscounted = subtotal - totalDiscounts
    const taxRate = parseFloat(document.querySelector('input[name="order[tax_rate]"]')?.value || 0)
    const tax = (totalDiscounted * taxRate) / 100
    const total = totalDiscounted + tax + shipping - shippingDiscount

    // Calculate profit and margin
    const profit = totalDiscounted - totalCost
    const marginPercentage = totalDiscounted > 0 ? ((profit / totalDiscounted) * 100) : 0

    this.updateDisplay({
      subtotal,
      tax,
      total,
      totalCost,
      profit,
      marginPercentage,
      orderDiscount: totalDiscounts,
      shippingDiscount,
      shipping,
      taxRate
    })
  }

  updateDisplay(values) {
    this.subtotalTarget.textContent = this.formatCurrency(values.subtotal)
    this.taxTarget.textContent = this.formatCurrency(values.tax)
    this.totalTarget.textContent = this.formatCurrency(values.total)
    this.totalCostTarget.textContent = this.formatCurrency(values.totalCost)
    this.totalProfitTarget.textContent = this.formatCurrency(values.profit)
    this.marginPercentageTarget.textContent = `${values.marginPercentage.toFixed(2)}%`
    this.discountTarget.textContent = `-${this.formatCurrency(values.orderDiscount)}`
    this.shippingDiscountTarget.textContent = `-${this.formatCurrency(values.shippingDiscount)}`
    this.shippingTarget.textContent = this.formatCurrency(values.shipping)

    if (this.hasTaxRateTarget) {
      this.taxRateTarget.textContent = values.taxRate
    }

    const indicator = this.marginIndicatorTarget
    indicator.classList.remove('bg-red-500', 'bg-green-500')
    indicator.classList.add(values.marginPercentage >= 8 ? 'bg-green-500' : 'bg-red-500')
  }

  handleTaxRateChange(event) {
    if (this.hasTaxRateTarget) {
      this.taxRateTarget.textContent = event.target.value
    }
    this.updateTotals()
  }

  handleShippingChange(event) {
    this.updateTotals()
  }

  handleDiscountChange(event) {
    this.updateTotals()
  }

  handleShippingDiscountChange(event) {
    const shipping = parseFloat(document.querySelector('input[name="order[shipping_cost]"]')?.value || 0)
    const type = document.querySelector('select[name="order[shipping_discount_type]"]')?.value
    const amount = parseFloat(document.querySelector('input[name="order[shipping_discount_amount]"]')?.value || 0)

    const discount = type === 'percentage'
      ? shipping * (amount / 100)
      : Math.min(amount, shipping)

    this.shippingDiscountTarget.textContent = `-${this.formatCurrency(discount)}`
    this.updateTotals()
  }

  formatCurrency(amount) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount)
  }
}