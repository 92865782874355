import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "quantity", "price", "cost", "costDisplay", "total", "profit", "marginInfo", "stockInfo",
    "discountType", "discountAmount"
  ]

  connect() {
    this.updateTotal()
    this.renderStockInfo()
    const sku = this.element.querySelector('input[name*="[sku]"]')?.value
    console.log("Order item connected with SKU:", sku)
  }

  incrementQuantity() {
    this.quantityTarget.value = parseInt(this.quantityTarget.value) + 1
    this.updateTotal()
  }

  decrementQuantity() {
    const newValue = parseInt(this.quantityTarget.value) - 1
    if (newValue >= 1) {
      this.quantityTarget.value = newValue
      this.updateTotal()
    }
  }

  applyMargin(e) {
    const margin = parseInt(e.currentTarget.dataset.margin)
    const cost = parseFloat(this.costTarget.value)
    if (!cost) {
      console.error('No cost found for margin calculation')
      return
    }
    const newPrice = cost * (1 + (margin / 100))
    this.priceTarget.value = newPrice.toFixed(2)
    this.updateTotal()
  }

  updateTotal() {
    const quantity = parseInt(this.quantityTarget.value) || 0
    const price = parseFloat(this.priceTarget.value) || 0
    const cost = parseFloat(this.costTarget.value) || 0

    if (!cost) {
      console.warn('Missing cost value:', {
        sku: this.element.querySelector('input[name*="[sku]"]')?.value,
        cost: this.costTarget?.value
      })
    }

    // Calculate total before discount
    const subtotal = quantity * price

    // Calculate discount
    const discountAmount = parseFloat(this.discountAmountTarget.value) || 0
    const discountType = this.discountTypeTarget.value
    const discountValue = discountType === 'percentage'
      ? (subtotal * (discountAmount / 100))
      : discountAmount

    // Calculate final total and profit
    const total = subtotal - discountValue
    const totalCost = quantity * cost
    const profit = total - totalCost
    const margin = total > 0 ? ((profit / total) * 100) : 0

    // Update UI
    this.totalTarget.textContent = this.formatCurrency(total)
    this.profitTarget.innerHTML = `
      <div>Profit: ${this.formatCurrency(profit)}</div>
      <div class="text-sm">
        Margin: <span class="font-bold ${margin >= 8 ? 'text-green-500' : 'text-red-500'}">
          ${margin.toFixed(2)}%
        </span>
      </div>
    `

    // Dispatch update event
    this.element.dispatchEvent(new CustomEvent('order-item:update', {
      bubbles: true,
      detail: {
        subtotal,
        total,
        profit,
        cost: totalCost,
        discountValue,
        quantity
      }
    }))
  }

  renderStockInfo() {
    const stockData = JSON.parse(this.element.dataset.stock || '[]')
    const totalStock = stockData.reduce((sum, s) => sum + (s.available || 0), 0)

    const stockHtml = `
      <div class="relative group">
        <div class="cursor-help">Stock: ${totalStock}</div>
        <div class="hidden group-hover:block absolute bottom-full left-0 mb-2 w-48 p-2 bg-space-800 rounded shadow-lg text-sm z-10">
          ${stockData.map(s => `
            <div class="mb-1 last:mb-0">
              <div class="font-medium">${s.warehouse.name}</div>
              <div class="text-space-400">
                ${s.available} available
                ${s.backordered ? `<br>${s.backordered} backordered` : ''}
              </div>
            </div>
          `).join('')}
        </div>
      </div>
    `

    if (this.hasStockInfoTarget) {
      this.stockInfoTarget.innerHTML = stockHtml
    }
  }

  async showProductInfo(e) {
    e.preventDefault()
    const sku = e.currentTarget.dataset.sku || this.element.querySelector('input[name*="[sku]"]')?.value

    if (!sku) {
      console.error('SKU is missing')
      this.showErrorModal('Unable to fetch product: SKU is missing')
      return
    }

    const loadingModal = this.showLoadingModal()

    try {
      const baseUrl = window.location.hostname === 'localhost'
        ? 'http://localhost:3000'
        : 'https://onyx.ktappliance.com'

      const response = await fetch(`${baseUrl}/api/v1/products/sku/${encodeURIComponent(sku)}`)

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      const product = data.results[0]

      if (!product) {
        throw new Error('Product not found')
      }

      // Ensure costs are present
      if (typeof product.cost !== 'number') {
        console.error('Missing cost for product:', sku)
      }

      // Filter and process add-ons
      if (product.additionalItems) {
        const quantity = parseInt(this.quantityTarget.value) || 1
        const price = parseFloat(this.priceTarget.value) || 0
        const totalPrice = quantity * price

        // Filter warranties based on total price
        const warranties = product.additionalItems
          .filter(item => item.addon_type === 'warranty')
        const filteredWarranties = this.filterWarranties(warranties, totalPrice)
        const otherItems = product.additionalItems
          .filter(item => item.addon_type !== 'warranty')

        product.additionalItems = [...filteredWarranties, ...otherItems]

        // Validate costs
        product.additionalItems.forEach(item => {
          if (typeof item.cost !== 'number') {
            console.warn(`Missing cost for addon: ${item.sku}`)
          }
        })
      }

      loadingModal.remove()
      this.showProductModal(product)
    } catch (error) {
      console.error('Failed to fetch product info:', error)
      loadingModal.remove()
      this.showErrorModal(error.message)
    }
  }

  filterWarranties(warranties, totalPrice) {
    const getWarrantyLimit = (warranty) => {
      let match = warranty.title.match(/under \$?([\d,]+)/)
      if (match) {
        return parseFloat(match[1].replace(',', ''))
      }
      match = warranty.sku.match(/PLAT\d?(\d+)/)
      if (match) {
        return parseFloat(match[1])
      }
      return Infinity
    }

    const eligibleLimit = warranties
      .map(w => getWarrantyLimit(w))
      .filter(limit => totalPrice <= limit)
      .sort((a, b) => a - b)[0]

    return warranties.filter(w => getWarrantyLimit(w) === eligibleLimit)
  }
  showProductModal(product) {
    const modal = document.createElement('div')
    modal.setAttribute('data-product-info-modal', '')
    modal.className = 'fixed inset-0 bg-black/50 z-50 overflow-auto p-4'

    const quantity = parseInt(this.quantityTarget.value) || 1
    const price = parseFloat(this.priceTarget.value) || 0
    const totalPrice = quantity * price

    modal.innerHTML = `
      <div class="mx-auto w-full max-w-3xl mt-12 bg-space-900 rounded-lg overflow-hidden flex flex-col">
        <div class="p-4 bg-space-800 flex items-center justify-between">
          <h2 class="text-lg font-bold">Product Information</h2>
          <button type="button" class="text-space-400 hover:text-space-300">
            <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
          </button>
        </div>
        <div class="flex-1 overflow-auto max-h-[calc(100vh-200px)]">
          <div class="p-4 space-y-4">
            <div class="flex gap-4">
              <div class="w-32 h-32 bg-space-800 rounded-lg flex-shrink-0">
                ${product.image ? `
                  <img src="${product.image}" class="w-full h-full object-contain rounded-lg" alt="${product.title}" />
                ` : ''}
              </div>
              <div class="flex-1 min-w-0">
                <div class="space-y-2">
                  <h3 class="text-lg font-bold">${product.title}</h3>
                  <div class="text-sm text-space-400">SKU: ${product.sku}</div>
                  <div class="text-sm text-space-400">${product.brand}</div>
                  <div class="text-sm text-space-400">${product.majorClassification} - ${product.minorClassification}</div>
                  <div class="text-sm text-space-400">MSRP: ${this.formatCurrency(product.price)}</div>
                </div>
                
                ${product.stock?.length ? `
                  <div class="mt-4">
                    <div class="text-sm font-medium text-space-300 mb-1">Availability</div>
                    ${product.stock.map(s => `
                      <div class="text-sm mb-1">
                        <span class="text-space-400">${s.warehouse.name}:</span>
                        <span class="font-medium ${s.available > 0 ? 'text-green-500' : 'text-red-500'}">
                          ${s.available} available
                        </span>
                        ${s.backordered ? `
                          <span class="text-yellow-500 ml-1">(${s.backordered} backordered)</span>
                        ` : ''}
                      </div>
                    `).join('')}
                  </div>
                ` : ''}
              </div>
            </div>

            ${product.description ? `
              <div class="border-t border-space-800 pt-4">
                <h4 class="font-bold mb-2">Product Description</h4>
                <div class="text-sm text-space-300">${product.description}</div>
              </div>
            ` : ''}

            ${product.additionalItems?.length ? this.renderAddonCategories(product.additionalItems) : ''}
          </div>
        </div>
      </div>
    `

    // Add event delegation for addon buttons
    modal.addEventListener('click', e => {
      if (e.target.matches('.add-addon-btn')) {
        e.preventDefault()
        this.addAdditionalItem(e)
      } else if (e.target === modal || e.target.closest('button[type="button"]')) {
        modal.remove()
      }
    })

    document.body.appendChild(modal)
  }

  renderAddonCategories(items) {
    const categories = items.reduce((acc, item) => {
      if (!acc[item.category]) acc[item.category] = []
      acc[item.category].push(item)
      return acc
    }, {})

    return `
      <div class="border-t border-space-800 mt-4 pt-4">
        <h4 class="font-bold mb-3">Available Add-ons</h4>
        <div class="space-y-6">
          ${Object.entries(categories).map(([category, items]) => `
            <div>
              <h5 class="text-sm font-medium text-space-300 mb-2">${category}</h5>
              <div class="grid gap-2">
                ${items.map(item => `
                  <div class="flex items-start justify-between p-3 bg-space-800 rounded-lg hover:bg-space-700">
                    <div class="flex-1 min-w-0">
                      <div class="font-medium">${item.title}</div>
                      <div class="text-sm text-space-400">SKU: ${item.sku}</div>
                      ${item.description ? `
                        <div class="text-sm text-space-400 mt-1">${item.description}</div>
                      ` : ''}
                    </div>
                    <div class="ml-4 flex flex-col items-end">
                      <div class="font-bold mb-1">${this.formatCurrency(item.price)}</div>
                      <button type="button"
                        class="add-addon-btn px-3 py-1 text-sm bg-blue-600 hover:bg-blue-500 rounded"
                        data-item='${JSON.stringify({
      sku: item.sku,
      title: item.title,
      price: item.price || 0,
      cost: item.cost || 0,
      addon_type: item.addon_type
    })}'>
                        Add to Order
                      </button>
                    </div>
                  </div>
                `).join('')}
              </div>
            </div>
          `).join('')}
        </div>
      </div>
    `
  }

  showLoadingModal() {
    const modal = document.createElement('div')
    modal.className = 'fixed inset-0 bg-black/50 z-50 flex items-center justify-center'
    modal.innerHTML = `
      <div class="bg-space-900 rounded-lg p-6 text-center">
        <div class="animate-spin inline-block w-8 h-8 border-4 border-space-400 border-t-transparent rounded-full mb-4"></div>
        <div class="text-space-300">Loading product information...</div>
      </div>
    `
    document.body.appendChild(modal)
    return modal
  }

  showErrorModal(message) {
    const modal = document.createElement('div')
    modal.className = 'fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4'
    modal.innerHTML = `
      <div class="bg-space-900 rounded-lg p-6 max-w-md w-full">
        <div class="text-red-500 font-bold mb-4">Error Loading Product</div>
        <div class="text-space-300 mb-4">${message}</div>
        <button class="w-full bg-blue-600 hover:bg-blue-500 rounded px-4 py-2">Close</button>
      </div>
    `
    modal.querySelector('button').addEventListener('click', () => modal.remove())
    document.body.appendChild(modal)
  }

  remove(e) {
    if (e.type === "click") {
      e.preventDefault()
      e.stopPropagation()
    }

    // Also remove any associated addons
    const sku = this.element.querySelector('input[name*="[sku]"]')?.value
    if (sku) {
      const addons = document.querySelectorAll('.order-item-wrapper')
      addons.forEach(addon => {
        const parentSkuInput = addon.querySelector('input[name*="[parent_sku]"]')
        if (parentSkuInput?.value === sku) {
          addon.remove()
        }
      })
    }

    this.element.remove()
    this.dispatch("totalChanged")
  }

  formatCurrency(amount) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount || 0)
  }
}