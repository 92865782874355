import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["icon", "tooltip"]
  static values = {
    productId: Number
  }

  sync() {
    // Disable icon and show loading state
    this.iconTarget.classList.add('animate-pulse')
    this.iconTarget.style.pointerEvents = 'none'
    this.tooltipTarget.textContent = 'Syncing...'

    const org = window.location.pathname.split('/')[2]

    fetch(`/ox/${org}/products/${this.productIdValue}/sync_variants`, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 'success') {
          // Show syncing state
          this.iconTarget.classList.remove('text-red-600')
          this.iconTarget.classList.add('text-yellow-400')

          // Update tooltip message
          if (this.hasTooltipTarget) {
            this.tooltipTarget.textContent = data.message
          }

          // Poll for updates every 2 seconds
          this.startPolling()
        }
      })
      .catch(error => {
        // Reset icon state on error
        this.iconTarget.classList.remove('animate-pulse')
        this.iconTarget.style.pointerEvents = 'auto'
        this.tooltipTarget.textContent = 'Sync failed. Click to try again.'
        console.error('Error:', error)
      })
  }

  startPolling() {
    this.pollInterval = setInterval(() => {
      this.checkSyncStatus()
    }, 2000)
  }

  checkSyncStatus() {
    const org = window.location.pathname.split('/')[2]
    fetch(`/ox/${org}/products/${this.productIdValue}/sync_status`)
      .then(response => response.json())
      .then(data => {
        if (data.status !== 'processing') {
          clearInterval(this.pollInterval)
          this.updateStatus(data)
        }
      })
  }

  updateStatus(data) {
    this.iconTarget.classList.remove('animate-pulse', 'text-yellow-400')
    if (data.status === 'success') {
      this.iconTarget.classList.remove('text-red-600')
      this.iconTarget.classList.add('text-green-400')
      this.tooltipTarget.textContent = `Last synced: ${new Date().toLocaleString()}`
    } else if (data.status === 'partial') {
      this.iconTarget.classList.remove('text-red-600')
      this.iconTarget.classList.add('text-orange-400')
      this.tooltipTarget.textContent = `Partially synced: ${data.synced_count} successful, ${data.failed_count} failed`
    } else {
      this.iconTarget.classList.add('text-red-600')
      this.iconTarget.style.pointerEvents = 'auto'
      this.tooltipTarget.textContent = `Sync failed: ${data.failed_count} variants failed`
    }
  }

  disconnect() {
    if (this.pollInterval) {
      clearInterval(this.pollInterval)
    }
  }
}