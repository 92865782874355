import { application } from "./application"

// Core controllers
import AnimationController from "./animation_controller"
import FlashController from "./flash_controller"
import ProgressController from "./progress_controller"
import SidebarController from "./sidebar"
import ThemeSwitcherController from "./theme_switcher_controller"
import TabsController from "./tabs_controller"
import ImageUploadController from './image_upload_controller'
import TrixController from './trix_controller'
import ProductController from './product_controller'
import ToggleController from "./toggle_controller"
import BrandFormController from "./brand_form_controller"
import ProductSyncController from "./product_sync_controller"

// Third party
import RailsNestedForm from '@stimulus-components/rails-nested-form'

// Builder controllers
import OrderFormController from "./builder/order_form_controller"
import OrderItemController from "./builder/order_item_controller"
import OrderTotalsController from "./builder/order_totals_controller"
import ProductSearchController from "./builder/product_search_controller"
import CustomerSearchController from "./builder/customer_search_controller"
import AdditionalItemsController from "./builder/additional_items_controller"
import CustomItemController from "./builder/custom_item_controller"

// Register core controllers
application.register("animation", AnimationController)
application.register("flash", FlashController)
application.register("progress", ProgressController)
application.register("sidebar", SidebarController)
application.register("theme-switcher", ThemeSwitcherController)
application.register("tabs", TabsController)
application.register("image-upload", ImageUploadController)
application.register("trix", TrixController)
application.register("product", ProductController)
application.register("toggle", ToggleController)
application.register("brand-form", BrandFormController)
application.register("product-sync", ProductSyncController)

// Register third party
application.register('nested-form', RailsNestedForm)

// Register builder controllers
application.register("order-form", OrderFormController)
application.register("order-item", OrderItemController)
application.register("order-totals", OrderTotalsController)
application.register("product-search", ProductSearchController)
application.register("customer-search", CustomerSearchController)
application.register("additional-items", AdditionalItemsController)
application.register("custom-item", CustomItemController)