import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "modal", "searchInput", "results", "content",
    "newCustomerModal", "newCustomerName", "newCustomerEmail", "newCustomerPhone",
    "billingAddress1", "billingAddress2", "billingCity", "billingState", "billingZip", "billingCountry",
    "shippingAddress1", "shippingAddress2", "shippingCity", "shippingState", "shippingZip", "shippingCountry",
    "sameAsBilling", "shippingFields"
  ]

  connect() {
    this.baseUrl = window.location.hostname === 'localhost' ?
      'http://localhost:3000' : 'https://onyx.ktappliance.com'
  }

  openSearchModal() {
    this.modalTarget.classList.remove('hidden')
    this.searchInputTarget.focus()
  }

  closeModal() {
    this.modalTarget.classList.add('hidden')
    this.resultsTarget.innerHTML = ''
    this.searchInputTarget.value = ''
  }

  openNewCustomerModal() {
    this.newCustomerModalTarget.classList.remove('hidden')
  }

  closeNewCustomerModal() {
    this.newCustomerModalTarget.classList.add('hidden')
    // Clear form
    this.clearCustomerForm()
  }

  async search() {
    clearTimeout(this.timeout)

    this.timeout = setTimeout(async () => {
      const query = this.searchInputTarget.value.trim()
      if (query.length < 2) {
        this.resultsTarget.innerHTML = ''
        return
      }

      try {
        const response = await fetch(`${this.baseUrl}/api/v1/customers/search?query=${encodeURIComponent(query)}`)
        const data = await response.json()
        this.renderResults(data)
      } catch (error) {
        console.error('Customer search failed:', error)
      }
    }, 300)
  }

  renderResults(customers) {
    const html = customers.length ? `
      <div class="divide-y divide-space-800">
        ${customers.map(customer => `
          <div class="p-4 hover:bg-space-800 cursor-pointer"
               data-action="click->customer-search#selectCustomer"
               data-customer='${JSON.stringify(customer).replace(/'/g, "&apos;")}'>
            <div class="font-bold">${customer.name}</div>
            <div class="text-sm text-space-400">${customer.email}</div>
            ${customer.addresses.length ? `
              <div class="text-sm text-space-400 mt-1">
                ${customer.addresses[0].address_1}, ${customer.addresses[0].city}, ${customer.addresses[0].state}
              </div>
            ` : ''}
          </div>
        `).join('')}
      </div>
    ` : `
      <div class="p-8 text-center text-space-400">
        No customers found
      </div>
    `

    this.resultsTarget.innerHTML = html
  }

  selectCustomer(event) {
    const customer = JSON.parse(event.currentTarget.dataset.customer)

    // Update the order form with customer data
    const form = this.element.closest('form')
    if (form) {
      const customerIdInput = form.querySelector('input[name="order[customer_id]"]') ||
        this.createHiddenInput(form, 'customer_id')
      customerIdInput.value = customer.id
    }

    // Update the display
    this.contentTarget.innerHTML = this.renderCustomerInfo(customer)

    // Close modal
    this.closeModal()
  }

  async createCustomer(event) {
    event.preventDefault()

    const customerData = {
      name: this.newCustomerNameTarget.value,
      email: this.newCustomerEmailTarget.value,
      phone: this.newCustomerPhoneTarget.value,
      billing_address: {
        address_1: this.billingAddress1Target.value,
        address_2: this.billingAddress2Target.value,
        city: this.billingCityTarget.value,
        state: this.billingStateTarget.value,
        zip: this.billingZipTarget.value,
        country: this.billingCountryTarget.value
      },
      shipping_address: this.sameAsBillingTarget.checked ? null : {
        address_1: this.shippingAddress1Target.value,
        address_2: this.shippingAddress2Target.value,
        city: this.shippingCityTarget.value,
        state: this.shippingStateTarget.value,
        zip: this.shippingZipTarget.value,
        country: this.shippingCountryTarget.value
      }
    }

    try {
      const response = await fetch(`${this.baseUrl}/api/v1/customers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify(customerData)
      })

      if (!response.ok) throw new Error('Failed to create customer')

      const customer = await response.json()
      this.selectCustomer({ currentTarget: { dataset: { customer: JSON.stringify(customer) } } })
      this.closeNewCustomerModal()
    } catch (error) {
      console.error('Failed to create customer:', error)
      // Show error message to user
    }
  }

  toggleShippingAddress() {
    if (this.sameAsBillingTarget.checked) {
      this.shippingFieldsTarget.classList.add('hidden')
    } else {
      this.shippingFieldsTarget.classList.remove('hidden')
    }
  }

  renderCustomerInfo(customer) {
    return `
      <div class="space-y-4">
        <div class="flex justify-between items-start">
          <div>
            <h3 class="font-bold text-lg">${customer.name}</h3>
            <div class="text-space-400">${customer.email}</div>
            ${customer.phone ? `<div class="text-space-400">${customer.phone}</div>` : ''}
          </div>
          <button type="button" 
            class="text-space-400 hover:text-space-300"
            data-action="click->customer-search#openSearchModal">
            Change
          </button>
        </div>
        
        ${customer.addresses.filter(a => a.isShipping).map(addr => `
          <div>
            <div class="text-sm font-bold text-space-400 mb-1">Shipping Address</div>
            <div class="text-sm">
              ${addr.address_1}<br>
              ${addr.address_2 ? `${addr.address_2}<br>` : ''}
              ${addr.city}, ${addr.state} ${addr.zip}
            </div>
          </div>
        `).join('')}
        
        ${customer.addresses.filter(a => a.isBilling).map(addr => `
          <div>
            <div class="text-sm font-bold text-space-400 mb-1">Billing Address</div>
            <div class="text-sm">
              ${addr.address_1}<br>
              ${addr.address_2 ? `${addr.address_2}<br>` : ''}
              ${addr.city}, ${addr.state} ${addr.zip}
            </div>
          </div>
        `).join('')}
      </div>
    `
  }

  clearCustomerForm() {
    [
      'newCustomerName', 'newCustomerEmail', 'newCustomerPhone',
      'billingAddress1', 'billingAddress2', 'billingCity', 'billingState', 'billingZip',
      'shippingAddress1', 'shippingAddress2', 'shippingCity', 'shippingState', 'shippingZip'
    ].forEach(field => {
      if (this.hasTarget(field)) {
        this[`${field}Target`].value = ''
      }
    })

    if (this.hasTarget('sameAsBilling')) {
      this.sameAsBillingTarget.checked = false
    }

    if (this.hasTarget('shippingFields')) {
      this.shippingFieldsTarget.classList.remove('hidden')
    }
  }

  createHiddenInput(form, name) {
    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = `order[${name}]`
    form.appendChild(input)
    return input
  }
}