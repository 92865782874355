import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["template"]

  add(event) {
    event.preventDefault()

    const template = document.querySelector('[data-custom-item-target="template"]')
    if (!template) return

    const content = template.content.cloneNode(true)
    const newIndex = new Date().getTime()

    // Replace all NEW_RECORD instances with the new index
    content.querySelectorAll('input').forEach(input => {
      input.name = input.name.replace('NEW_RECORD', newIndex)
    })

    // Find target container and append
    const targetContainer = document.querySelector('[data-nested-form-target="target"]')
    if (targetContainer) {
      targetContainer.appendChild(content)

      // Remove empty state if it exists
      const emptyState = document.querySelector('[data-nested-form-target="empty"]')
      if (emptyState) emptyState.remove()
    }
  }
}