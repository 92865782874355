import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal", "itemsList"]

  connect() {
    this.categories = {
      warranty: { title: "Warranties", items: [] },
      service: { title: "Services", items: [] },
      installation: { title: "Installation", items: [] }
    }
  }

  openModal(event) {
    const additionalItems = JSON.parse(event.currentTarget.dataset.additionalItems || '[]')
    const itemPrice = parseFloat(event.currentTarget.dataset.price || 0)
    const itemQuantity = parseInt(event.currentTarget.dataset.quantity || 1)
    this.currentProductSku = event.currentTarget.dataset.sku

    // Filter warranties based on total price
    const totalPrice = itemPrice * itemQuantity
    console.log(`Filtering warranties for total price: ${totalPrice}`)

    // Group and filter items by category
    this.categories = {
      warranty: {
        title: "Warranties",
        items: this.filterWarrantiesByPrice(additionalItems.filter(item => item.addon_type === 'warranty'), totalPrice)
      },
      service: {
        title: "Services",
        items: additionalItems.filter(item => item.addon_type === 'service')
      },
      installation: {
        title: "Installation",
        items: additionalItems.filter(item => item.addon_type === 'installation')
      }
    }

    this.renderItems()
    this.modalTarget.classList.remove('hidden')
  }

  filterWarrantiesByPrice(warranties, totalPrice) {
    return warranties.filter(warranty => {
      const priceLimit = this.extractPriceLimit(warranty.sku, warranty.title)
      return totalPrice <= priceLimit
    }).sort((a, b) => {
      const limitA = this.extractPriceLimit(a.sku, a.title)
      const limitB = this.extractPriceLimit(b.sku, b.title)
      return limitA - limitB
    })
  }

  extractPriceLimit(sku, title) {
    const matches = title.match(/under \$?([\d,]+)\.?00/)
    if (matches) {
      return parseFloat(matches[1].replace(',', ''))
    }
    const skuMatch = sku.match(/PLAT3?(\d+)/)
    return skuMatch ? parseFloat(skuMatch[1]) : Infinity
  }

  renderItems() {
    const html = Object.values(this.categories)
      .filter(category => category.items.length > 0)
      .map(category => `
        <div class="mb-6 last:mb-0">
          <h3 class="text-lg font-bold mb-3">${category.title}</h3>
          <div class="space-y-2">
            ${category.items.map(item => `
              <div class="flex items-center justify-between p-3 bg-space-800 rounded-lg">
                <div>
                  <div class="font-medium">${item.title}</div>
                  <div class="text-sm text-space-400">SKU: ${item.sku}</div>
                  ${item.description ? `<div class="text-sm text-space-400">${item.description}</div>` : ''}
                </div>
                <button type="button"
                  class="px-3 py-1 text-sm bg-blue-600 hover:bg-blue-500 rounded"
                  data-action="additional-items#addItem"
                  data-item='${JSON.stringify({
        sku: item.sku,
        title: item.title,
        price: item.price,
        cost: item.cost,
        addon_type: item.addon_type
      })}'>
                  Add
                </button>
              </div>
            `).join('')}
          </div>
        </div>
      `).join('')

    this.itemsListTarget.innerHTML = html
  }

  addItem(event) {
    const itemData = JSON.parse(event.currentTarget.dataset.item)

    // Create template-based nested item
    const template = document.querySelector('[data-nested-form-target="template"]')
    if (!template) {
      console.error("Template not found for nested form")
      return
    }

    const content = template.content.cloneNode(true)
    const wrapper = content.querySelector('.order-item-wrapper')
    const newIndex = new Date().getTime()

    if (wrapper) {
      console.log("Setting up new add-on item:", itemData)

      // Update all input names with the new index
      wrapper.querySelectorAll('input').forEach(input => {
        input.name = input.name.replace('NEW_RECORD', newIndex)
      })

      // Set fields for add-on item
      const fields = {
        '[name$="[sku]"]': itemData.sku,
        '[name$="[price]"]': itemData.price,
        '[name$="[cost]"]': itemData.cost,
        '[name$="[quantity]"]': "1",
        '[name$="[is_addon]"]': "true",
        '[name$="[addon_type]"]': itemData.addon_type,
        '[name$="[parent_sku]"]': this.currentProductSku
      }

      Object.entries(fields).forEach(([selector, value]) => {
        const input = wrapper.querySelector(selector)
        if (input) {
          input.value = value
          console.log(`Set ${selector} to ${value}`)
        } else {
          console.warn(`Input not found for ${selector}`)
        }
      })

      // Update visible content
      wrapper.querySelector('.product-title').textContent = itemData.title
      wrapper.querySelector('.product-sku').textContent = `SKU: ${itemData.sku} (Add-on)`

      // Add visual indication that this is an add-on
      wrapper.classList.add('ml-8', 'border-l-4', 'border-blue-600')

      // Find parent item and insert after it
      const parentItem = document.querySelector(`[data-sku="${this.currentProductSku}"]`)
        ?.closest('.order-item-wrapper')

      if (parentItem) {
        console.log("Found parent item, inserting after")
        parentItem.insertAdjacentElement('afterend', wrapper)
      } else {
        console.warn("Parent item not found, appending to container")
        const targetContainer = document.querySelector('[data-nested-form-target="target"]')
        if (targetContainer) targetContainer.appendChild(wrapper)
      }

      // Trigger total update
      wrapper.dispatchEvent(new CustomEvent('order-item:update', {
        bubbles: true,
        detail: {
          total: itemData.price,
          cost: itemData.cost,
          quantity: 1
        }
      }))
    }

    this.closeModal()
  }

  closeModal() {
    this.modalTarget.classList.add('hidden')
  }
}