import React, { useEffect, useState, useRef } from 'react'
import { User, Company, Customer, Warehouse, OrderItem, ItemStock, AdditionalItem } from '../dataTypes'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, useFieldArray } from "react-hook-form"
import { z } from "zod"

import { Loader2, Clock, CheckCircle2, XCircle, AlertCircle } from "lucide-react"

import { orderTypes, orderSubTypes, orderSource, orderQueue, formatNumber, OrderSubType, OrderSource, OrderType, OrderQueue } from "@/lib/utils"

import CustomerManagement from '@/components/order_components/CustomerManagement'
import CustomItem from '@/components/order_components/CustomItem'

// Components
import { Separator } from "@/components/ui/separator"
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"

import WarehouseStock from "@/components/order_components/WarehouseStock"

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectLabel,
  SelectValue,
} from "@/components/ui/select"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { Label } from "@/components/ui/label"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { Textarea } from "@/components/ui/textarea"

interface ProcessedOrderItem extends OrderItem {
  addons?: OrderItem[]
  is_addon?: boolean
  parent_id?: string | number
  parent_sku?: string
}

interface OrderViewProps {
  orderId?: number
  currentUser: User
  companiesAvailable?: Company[]
  usersAvailable?: User[]
  defaultWarehouse?: number
  publicId?: string
  domain?: string
}

interface OrderData {
  id?: number
  customer: Customer
  orderQueue: OrderQueue
  orderType: OrderType
  orderSubType: OrderSubType
  orderSource: OrderSource
  orderStatus: string
  orderShippingStatus: string
  orderPaymentStatus: string
  orderItems: OrderItem[]
  createdDate?: string
  promiseDate?: string | null
  expireDate?: string | null
  taxRate: number
  shippingCost: number
  orderTax: number
  total?: number
  orderSalesRep: string
  sellercloud_id?: string
}

const formSchema = z.object({
  customer: z.any(),
  orderQueue: z.enum([
    'new_orders', 'quotes_queue', 'processing_queue', 'ready_to_ship',
    'pending_dropship', 'picked_orders', 'done_orders', 'problem_order',
    'billing_issue', 'back_order', 'revision', 'pending_refund'
  ]),
  orderType: z.enum([
    'draft', 'quote', 'retail_order', 'b2b_order',
    'builder_order', 'marketplace_order'
  ]),
  orderSubType: z.enum([
    'st_not_set', 'showroom', 'amazon', 'walmart',
    'sears', 'wholesale'
  ]),
  orderSource: z.enum([
    'os_not_set', 'online_order', 'phone_order', 'showroom_order'
  ]),
  orderStatus: z.string(),
  orderShippingStatus: z.string(),
  orderPaymentStatus: z.string(),
  orderCompany: z.number(),
  orderSalesRep: z.string(),
  taxRate: z.coerce.number().min(0).max(100).default(0),
  shippingCost: z.coerce.number().default(0),
  orderTax: z.coerce.number().default(0),
  promiseDate: z.string().optional().nullable(),
  expireDate: z.string().optional().nullable(),
  shipByDate: z.date().optional(),
  orderItems: z.array(z.any()),
  notes: z.array(z.object({
    noteType: z.string(),
    content: z.string(),
  }))
})

type FormData = z.infer<typeof formSchema>
const OrderView: React.FC<OrderViewProps> = ({
  orderId,
  currentUser,
  companiesAvailable,
  usersAvailable,
  defaultWarehouse,
  publicId,
  domain
}) => {
  const [editMode, setEditMode] = useState(!orderId)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [orderData, setOrderData] = useState<OrderData | null>(null)
  const [productSuggestions, setProductSuggestions] = useState<OrderItem[]>([])
  const [localPriceInputs, setLocalPriceInputs] = useState<{ [key: string]: string }>({})
  const [subTotal, setSubTotal] = useState(0)
  const [taxes, setTaxes] = useState(0)
  const [shipping, setShipping] = useState(0)
  const [total, setTotal] = useState(0)
  const [profit, setProfit] = useState(0)
  const [profitPercentage, setProfitPercentage] = useState(0)
  const [marginPassed, setMarginPassed] = useState(false)
  const [itemToRemove, setItemToRemove] = useState<{ index: number, id: string } | null>(null)

  const suggestionsRef = useRef<HTMLDivElement>(null)
  const suggestionsInputRef = useRef<HTMLInputElement>(null)

  const defaultExpireDate = new Date()
  defaultExpireDate.setMonth(defaultExpireDate.getMonth() + 1)

  const form = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      orderQueue: 'quotes_queue',
      orderStatus: 'pending',
      orderShippingStatus: 'pending_shipping',
      orderPaymentStatus: 'pending_payment',
      orderType: 'quote',
      orderSubType: 'showroom',
      orderSource: 'showroom_order',
      orderSalesRep: currentUser.id.toString(),
      orderCompany: currentUser.organization,
      orderItems: [],
      notes: [],
      taxRate: 0,
      shippingCost: 0,
      orderTax: 0,
      promiseDate: null,
      expireDate: defaultExpireDate.toISOString(),
    },
  })

  const { fields, append, remove, update } = useFieldArray({
    control: form.control,
    name: "orderItems"
  })

  // Format helpers
  const formatPrice = (price: number | string | undefined): string => {
    if (typeof price === 'number') {
      return price.toFixed(2)
    } else if (typeof price === 'string') {
      const numPrice = parseFloat(price)
      return isNaN(numPrice) ? '0.00' : numPrice.toFixed(2)
    }
    return '0.00'
  }

  const formatDateForInput = (date: string | null | undefined): string => {
    if (!date) return ''
    return new Date(date).toISOString().split('T')[0]
  }

  // Addon helpers
  const formatAddonForDisplay = (addon: AdditionalItem, parentItem: OrderItem, index: number): OrderItem => {
    console.log('Formatting addon with parent:', {
      addon: addon.sku,
      parentSku: parentItem.sku,
      parentId: parentItem.id
    })

    const addonItem: OrderItem = {
      id: addon.id,
      sku: addon.sku,
      title: addon.title,
      quantity: 1,
      price: Number(addon.price) || 0,
      cost: Number(addon.cost) || 0,
      is_addon: true,
      addon_type: addon.addon_type,
      parent_id: parentItem.id,
      parent_sku: parentItem.sku,
      parent_index: index,
      ship_from_warehouse_id: parentItem.ship_from_warehouse_id
    }

    return addonItem
  }

  // Update addAddonToItem
  const addAddonToItem = (mainItem: OrderItem, addon: AdditionalItem, index: number) => {
    console.log('Adding addon with mainItem:', {
      mainItemId: mainItem.id,
      mainItemSku: mainItem.sku,
      mainItemIndex: index
    })

    const addonItem: OrderItem = {
      id: addon.id,
      title: addon.title,
      sku: addon.sku,
      quantity: 1,
      price: Number(addon.price) || 0,
      cost: Number(addon.cost) || 0,
      is_addon: true,
      addon_type: addon.addon_type,
      parent_id: mainItem.id,  // Use the actual ID, not a generated UUID
      parent_sku: mainItem.sku,
      parent_index: index,
      ship_from_warehouse_id: mainItem.ship_from_warehouse_id
    }

    console.log('Created addon item:', addonItem)
    append(addonItem)
  }

  // Product management
  const addProductToOrder = (product: OrderItem) => {
    const newItem: OrderItem = {
      id: product.id,
      quantity: 1,
      sku: product.sku,
      price: product.price,
      originalPrice: product.price,
      cost: product.cost,
      title: product.title,
      brand: product.brand,
      image: product.image,
      minorClassification: product.minorClassification,
      majorClassification: product.majorClassification,
      files: product.files,
      stock: product.stock,
      available: product.available,
      additionalItems: product.additionalItems,
      ship_from_warehouse_id: product.ship_from_warehouse_id || defaultWarehouse
    }

    append(newItem)
    if (suggestionsInputRef.current) {
      suggestionsInputRef.current.value = ""
    }
    setProductSuggestions([])
  }

  const removeProductFromOrder = (index: number) => {
    console.log('Removing item at index:', index)
    console.log('Current fields:', fields)

    const itemToRemove = fields[index]
    console.log('Item to remove:', itemToRemove)

    // If this is a main item, we should also remove its addons
    if (!itemToRemove.is_addon) {
      // Get indexes of all addons for this item
      const addonIndexes = fields.reduce((indexes: number[], field, idx) => {
        if (field.is_addon &&
          (field.parent_id === itemToRemove.id || field.parent_sku === itemToRemove.sku)) {
          indexes.push(idx)
        }
        return indexes
      }, [])

      // Remove addons first (in reverse order to not affect indexes)
      addonIndexes.reverse().forEach(idx => remove(idx))
    }

    // Remove the item itself
    remove(index)

    // Recalculate totals
    setTimeout(() => calculateTotals(), 0)
  }

  const updateProductInOrder = (index: number, field: keyof OrderItem, value: number | string) => {
    console.log(`Updating ${field} for item at index ${index} to value:`, value)  // Debug log

    if (field === 'quantity') {
      const numValue = parseInt(value.toString(), 10)
      if (numValue <= 0) {
        setItemToRemove({ index, id: fields[index].id.toString() })
      } else {
        const updatedItem = { ...fields[index], [field]: numValue }
        update(index, updatedItem)
        console.log('Updated item after quantity change:', updatedItem)  // Debug log
      }
    } else if (field === 'price' || field === 'cost') {
      const numValue = parseFloat(value.toString())
      if (!isNaN(numValue)) {
        const updatedItem = { ...fields[index], [field]: numValue }
        update(index, updatedItem)
        console.log('Updated item after price/cost change:', updatedItem)  // Debug log
      }
    } else {
      const updatedItem = { ...fields[index], [field]: value }
      update(index, updatedItem)
      console.log('Updated item after other field change:', updatedItem)  // Debug log
    }

    // Force recalculation of totals after any update
    setTimeout(() => calculateTotals(), 0)
  }

  // Form handlers
  const handlePriceChange = (index: number, value: string) => {
    setLocalPriceInputs(prev => ({
      ...prev,
      [index]: value
    }))
  }

  const handlePriceBlur = (index: number, value: string) => {
    const numericValue = parseFloat(value)
    if (!isNaN(numericValue)) {
      console.log('Price blur update:', index, numericValue)  // Debug log
      updateProductInOrder(index, 'price', numericValue)
    }
    setLocalPriceInputs(prev => ({
      ...prev,
      [index]: undefined
    }))
  }

  const handleProductSearchInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (value.length >= 2) {
      const response = await fetch(`/api/v1/products/search?query=${encodeURIComponent(value)}`)
      if (response.ok) {
        const data = await response.json()
        setProductSuggestions(data.results)
      } else {
        setProductSuggestions([])
      }
    } else {
      setProductSuggestions([])
    }
  }

  // Calculations
  const calculateOrderTax = (subtotal: number, rate: number): number => {
    return (subtotal * (rate / 100))
  }

  const calculateTotals = () => {
    const items = form.getValues('orderItems')
    const newSubTotal = items.reduce((sum, item) => sum + (item.price * item.quantity), 0)
    const taxRate = form.watch('taxRate') || 0
    const shippingCost = form.watch('shippingCost') || 0
    const newTaxes = calculateOrderTax(shippingCost + newSubTotal, taxRate)
    const newTotal = newSubTotal + newTaxes
    const newProfit = items.reduce((sum, item) => sum + ((item.price - item.cost) * item.quantity), 0)
    const newProfitPercentage = (newTotal > 0) ? (newProfit / newTotal) * 100 : 0

    setSubTotal(newSubTotal)
    setTaxes(newTaxes)
    setShipping(shippingCost)
    form.setValue('orderTax', newTaxes)
    setTotal(newTotal)
    setProfit(newProfit)
    setProfitPercentage(newProfitPercentage)
    setMarginPassed(newProfitPercentage >= 8)
  }

  // API interactions
  const syncToSC = async () => {
    setIsSubmitting(true)
    try {
      const response = await fetch(`/api/v1/orders/send_to_sellercloud/${orderId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector<HTMLMetaElement>('meta[name="csrf-token"]')?.content || '',
        }
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const result = await response.json()
      if (result.success) {
        alert('Order successfully synced')
      } else {
        alert(result.error || 'Failed to sync order. Please try again.')
      }
    } catch (error) {
      console.error('There was a problem syncing the order:', error)
      alert('An error occurred while syncing the order. Please try again.')
    }
    setIsSubmitting(false)
  }

  const print2PDF = (publicId: string, domain: string) => {
    const url = `https://onyx.ktappliance.com/ox/${domain}/orders/${publicId}.pdf`
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  const validateOrder = (values: FormData): { isValid: boolean; message?: string } => {
    if (!values.customer || !values.customer.id) {
      return {
        isValid: false,
        message: "Please select a customer before submitting the order."
      }
    }

    // Add any other validations you need here
    if (fields.length === 0) {
      return {
        isValid: false,
        message: "Please add at least one item to the order."
      }
    }

    return { isValid: true }
  }

  // Form submission
  const onSubmit = async (values: FormData) => {
    // Validate before proceeding
    const validation = validateOrder(values)
    if (!validation.isValid) {
      alert(validation.message)
      return
    }

    setIsSubmitting(true)
    setEditMode(false)

    // First collect main items for reference
    const mainItemsMap = values.orderItems.reduce((acc, item, index) => {
      if (!item.is_addon) {
        acc[item.sku] = {
          id: item.id,
          index,
          sku: item.sku
        }
      }
      return acc
    }, {} as Record<string, { id: number | string, index: number, sku: string }>)

    const sanitizedItems = values.orderItems.map(item => {
      const isAddon = Boolean(item.is_addon)
      let parentId = item.parent_id
      let parentSku = item.parent_sku

      // If this is an addon, ensure we're using the correct parent ID
      if (isAddon && parentSku) {
        const parent = mainItemsMap[parentSku]
        if (parent) {
          parentId = parent.id
        }
      }

      return {
        ...item,
        quantity: Number(item.quantity) || 1,
        price: Number(item.price) || 0,
        cost: Number(item.cost) || 0,
        is_addon: isAddon,
        parent_id: parentId,
        parent_sku: parentSku,
        addon_type: item.addon_type,
        ship_from_warehouse_id: isAddon ? null : item.ship_from_warehouse_id,
        // Ensure these fields are included for addons
        title: item.title,
        custom_title: item.title,
      }
    })

    const submission = {
      ...values,
      orderItems: sanitizedItems,
      orderSubType: values.orderSubType,
      orderSource: values.orderSource,
    }

    try {
      const response = await fetch(orderId ? `/api/v1/orders/submit/${orderId}` : '/api/v1/orders/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector<HTMLMetaElement>('meta[name="csrf-token"]')?.content || '',
        },
        body: JSON.stringify(submission),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const result = await response.json()
      if (result.success) {
        alert(`Order successfully ${orderId ? 'updated' : 'created'}!`)
        if (!orderId) {
          window.location.href = result.redirect || '/'
        }
      } else {
        alert(result.error || `Failed to ${orderId ? 'update' : 'create'} order. Please try again.`)
      }
    } catch (error) {
      console.error('Submission error:', error)
      alert(`An error occurred while ${orderId ? 'updating' : 'creating'} the order. Please try again.`)
    }

    setIsSubmitting(false)
  }

  useEffect(() => {
    if (!orderId) return

    const fetchOrder = async () => {
      try {
        const response = await fetch(`/api/v1/orders/${orderId}`)
        if (!response.ok) throw new Error('Failed to fetch order')

        const orderData: OrderData = await response.json()
        setOrderData(orderData)

        // Process all items including nested addons
        const processedItems: ProcessedOrderItem[] = []

        orderData.orderItems.forEach((item: OrderItem) => {
          // Process main item
          processedItems.push({
            ...item,
            price: typeof item.price === 'string' ? parseFloat(item.price) : (item.price ?? 0),
            cost: typeof item.cost === 'string' ? parseFloat(item.cost) : (item.cost ?? 0),
            quantity: parseInt(item.quantity?.toString() || '1', 10),
            is_addon: false,
            parent_id: undefined,
            parent_sku: undefined
          })

          // Process nested addons if they exist
          if (item.addons && Array.isArray(item.addons)) {
            item.addons.forEach(addon => {
              processedItems.push({
                ...addon,
                price: typeof addon.price === 'string' ? parseFloat(addon.price) : (addon.price ?? 0),
                cost: typeof addon.cost === 'string' ? parseFloat(addon.cost) : (addon.cost ?? 0),
                quantity: parseInt(addon.quantity?.toString() || '1', 10),
                is_addon: true,
                parent_id: item.id,
                parent_sku: item.sku
              })
            })
          }
        })

        // Set all form values at once
        form.reset({
          customer: orderData.customer,
          orderQueue: orderData.orderQueue,
          orderType: orderData.orderType,
          orderSubType: orderData.orderSubType,
          orderSource: orderData.orderSource,
          orderStatus: orderData.orderStatus,
          orderShippingStatus: orderData.orderShippingStatus,
          orderPaymentStatus: orderData.orderPaymentStatus,
          orderItems: processedItems,
          orderSalesRep: orderData.orderSalesRep,
          taxRate: orderData.taxRate,
          shippingCost: orderData.shippingCost,
          orderTax: orderData.orderTax,
          promiseDate: orderData.promiseDate,
          expireDate: orderData.expireDate,
          notes: [],  // Set your notes here if you have them
          orderCompany: currentUser.organization
        })

        console.log('Processed items with addons:', processedItems)
        calculateTotals()  // Recalculate totals after setting items

      } catch (error) {
        console.error('Error fetching order:', error)
      }
    }

    fetchOrder()
  }, [orderId])

  useEffect(() => {
    calculateTotals()
  }, [form.watch('taxRate'), fields])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (suggestionsRef.current && !suggestionsRef.current.contains(event.target as Node)) {
        if (suggestionsInputRef.current) {
          suggestionsInputRef.current.value = ""
        }
        setProductSuggestions([])
      }
    }

    document.addEventListener('mousedown', handleClickOutside, { capture: true })
    return () => document.removeEventListener('mousedown', handleClickOutside, { capture: true })
  }, [])

  // Order Summary Component
  const OrderSummary: React.FC<{ order: OrderData | null; type: string }> = ({ order, type }) => {
    const getStatusIcon = (status: string) => {
      switch (status) {
        case 'completed':
          return <CheckCircle2 className="w-4 h-4 text-green-500" />
        case 'cancelled':
          return <XCircle className="w-4 h-4 text-red-500" />
        case 'pending':
          return <Clock className="w-4 h-4 text-yellow-500" />
        default:
          return <AlertCircle className="w-4 h-4 text-gray-500" />
      }
    }

    return (
      <div className="bg-space-900 p-4 rounded-md h-full">
        <div className="flex items-start justify-between">
          <div>
            <h2 className="text-lg font-semibold mb-2">Order Summary</h2>
            <div className="space-y-1 text-sm">
              {order?.createdDate && (
                <div className="flex items-center gap-2">
                  <span className="text-space-400">Created:</span>
                  <span>{new Date(order.createdDate).toLocaleDateString()}</span>
                </div>
              )}
              <div className="flex items-center gap-2">
                <span className="text-space-400">Status:</span>
                <div className="flex items-center gap-1">
                  {getStatusIcon(order?.orderStatus || 'pending')}
                  <span className="capitalize">{order?.orderStatus || 'Pending'}</span>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <span className="text-space-400">Payment:</span>
                <div className="flex items-center gap-1">
                  {getStatusIcon(order?.orderPaymentStatus || 'pending')}
                  <span className="capitalize">
                    {order?.orderPaymentStatus?.replace('_', ' ') || 'Pending Payment'}
                  </span>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <span className="text-space-400">Shipping:</span>
                <div className="flex items-center gap-1">
                  {getStatusIcon(order?.orderShippingStatus || 'pending')}
                  <span className="capitalize">
                    {order?.orderShippingStatus?.replace('_', ' ') || 'Pending Shipping'}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {order?.id && (
            <div className="text-right">
              <div className="text-2xl font-bold">#{order.id}</div>
              <div className="text-space-400 text-sm">{type}</div>
            </div>
          )}
        </div>
      </div>
    )
  }

  // Render Order Items
  const renderOrderItems = () => (
    <div className="space-y-2">
      {fields.map((item, index) => {
        // Skip addon items as they'll be rendered with their parent
        if (item.is_addon) return null

        // Find all addons for this item
        const addons = fields.filter(f =>
          f.is_addon && (
            (f.parent_id && f.parent_id === item.id) ||
            (f.parent_sku && f.parent_sku === item.sku) ||
            f.parent_index === index
          )
        )

        return (
          <div key={`${item.id}-${index}`} className="space-y-2">
            {/* Main item */}
            <div className="flex items-center p-2 gap-4 bg-space-800 rounded-lg">
              <div className="w-24 h-24 rounded-md overflow-hidden bg-space-800">
                {!item.is_custom ? (
                  <img
                    src={item.image}
                    alt={item.title || "Product"}
                    className="w-full h-full object-contain"
                  />
                ) : (
                  <div className="w-full h-full flex items-center justify-center bg-space-700 text-space-400 text-sm font-bold">
                    CUSTOM
                  </div>
                )}
              </div>
              <div className="flex flex-col justify-center">
                <h1 className="text-xl font-bold">{item.title || 'Untitled'}</h1>
                <h2 className="font-semibold">{typeof item.brand === 'string' ? item.brand : ''}</h2>
                <h3>
                  <small>SKU: </small>{item.sku}
                  {item.minorClassification && (
                    <>| <small>CLASS: </small>{item.minorClassification}</>
                  )}
                </h3>
              </div>
              <div className="flex items-center space-x-2 ml-auto">
                {(item.stock && item.stock.length > 0) && (
                  <WarehouseStock
                    item={item}
                  />
                )}
                <Input
                  type="number"
                  value={item.quantity}
                  min={0}
                  onChange={(e) => updateProductInOrder(index, 'quantity', parseInt(e.target.value))}
                  className="w-16 bg-space-700 border-space-600"
                  disabled={!editMode}
                />
                <Input
                  type="number"
                  value={localPriceInputs[index] ?? formatPrice(item.price)}
                  onChange={(e) => handlePriceChange(index, e.target.value)}
                  onBlur={(e) => handlePriceBlur(index, e.target.value)}
                  min={0}
                  step={0.01}
                  className="w-28 bg-space-700 border-space-600"
                  disabled={!editMode}
                />
                <Input
                  type="number"
                  value={formatPrice(item.cost)}
                  className="w-28 bg-space-700 border-space-600"
                  disabled={true}
                />
                {editMode && (
                  <Button
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      removeProductFromOrder(index)
                    }}
                    variant="destructive"
                    size="sm"
                    disabled={!editMode}
                  >
                    Remove
                  </Button>
                )}
              </div>
            </div>

            {/* Item Controls (Margin Buttons) */}
            {(editMode && !item.is_custom && item.cost > 0) ? (
              <div className="flex items-center gap-4 mt-2">
                <Button
                  className="bg-green-500"
                  disabled={!editMode}
                  onClick={(e) => {
                    e.preventDefault()
                    const newPrice = parseFloat(item.cost) * 1.20 // 20% margin
                    updateProductInOrder(index, 'price', parseFloat(newPrice.toFixed(2)))
                  }}
                >
                  Use 20% margin
                </Button>
                <Button
                  className="bg-green-500"
                  disabled={!editMode}
                  onClick={(e) => {
                    e.preventDefault()
                    const newPrice = parseFloat(item.cost) * 1.25 // 25% margin
                    updateProductInOrder(index, 'price', parseFloat(newPrice.toFixed(2)))
                  }}
                >
                  Use 25% margin
                </Button>
              </div>
            ) : null}

            {/* Addons Section */}
            {addons.length > 0 && (
              <div className="ml-8 mt-2 space-y-2">
                {addons.map((addon) => (
                  <div
                    key={`addon-${addon.id}`}
                    className="flex items-center p-2 gap-4 bg-space-700 rounded-md border-l-4 border-blue-500"
                  >
                    <div className="flex-1">
                      <div className="flex items-center gap-2">
                        <span className="bg-blue-500 text-white text-xs px-2 py-1 rounded">
                          {addon.addon_type || 'Addon'}
                        </span>
                        <h3 className="font-medium text-space-200">{addon.title}</h3>
                      </div>
                      <p className="text-sm text-space-400">SKU: {addon.sku}</p>
                      {addon.description && (
                        <p className="text-sm text-space-400 mt-1">{addon.description}</p>
                      )}
                    </div>
                    {editMode && (
                      <div className="flex items-center space-x-2">
                        <Input
                          type="number"
                          value={addon.quantity}
                          min={0}
                          onChange={(e) => {
                            const addonIndex = fields.findIndex(f => f.id === addon.id)
                            updateProductInOrder(addonIndex, 'quantity', parseInt(e.target.value))
                          }}
                          className="w-16 bg-space-600 border-space-500"
                        />
                        <Input
                          type="number"
                          value={formatPrice(addon.price)}
                          min={0}
                          step={0.01}
                          onChange={(e) => {
                            const addonIndex = fields.findIndex(f => f.id === addon.id)
                            updateProductInOrder(addonIndex, 'price', parseFloat(e.target.value))
                          }}
                          className="w-28 bg-space-600 border-space-500"
                        />
                        <Input
                          type="number"
                          value={formatPrice(addon.cost)}
                          className="w-28 bg-space-600 border-space-500"
                          disabled={true}
                        />
                        <Button
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            const addonIndex = fields.findIndex(f => f.id === addon.id)
                            removeProductFromOrder(addonIndex)
                          }}
                          variant="destructive"
                          size="sm"
                          disabled={!editMode}
                        >
                          Remove
                        </Button>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}

            {/* Add Options Dialog */}
            {editMode && item.additionalItems && item.additionalItems.length > 0 && (
              <Dialog>
                <DialogTrigger asChild>
                  <Button variant="outline" className="mt-2">
                    Add Options
                  </Button>
                </DialogTrigger>
                <DialogContent className="max-w-2xl fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-50">
                  <DialogHeader>
                    <DialogTitle>Additional Items</DialogTitle>
                    <DialogDescription>
                      Select additional items for {item.title}
                    </DialogDescription>
                  </DialogHeader>
                  <div className="grid grid-cols-1 gap-4 max-h-[60vh] overflow-y-auto">
                    {item.additionalItems.map((addon: AdditionalItem) => (
                      <div
                        key={addon.id}
                        className="flex items-center justify-between p-4 bg-space-800 rounded-lg"
                      >
                        <div className="flex-1">
                          <div className="flex items-center gap-2">
                            <span className="bg-blue-500 text-white text-xs px-2 py-1 rounded">
                              {addon.addon_type || 'Option'}
                            </span>
                            <h3 className="font-semibold text-space-100">{addon.title}</h3>
                          </div>
                          <div className="mt-1 text-sm text-space-300">
                            SKU: {addon.sku} | Price: ${addon.price}
                          </div>
                        </div>
                        <Button
                          onClick={() => addAddonToItem(item, addon, index)}
                          className="ml-4 bg-blue-500 hover:bg-blue-600"
                          disabled={addons.some(a => a.sku === addon.sku)}
                        >
                          {addons.some(a => a.sku === addon.sku) ? 'Added' : 'Add'}
                        </Button>
                      </div>
                    ))}
                  </div>
                </DialogContent>
              </Dialog>
            )}
          </div>
        )
      })}
    </div>
  )

  // Main render
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <div className="flex items-end gap-4 w-full">
          {orderId && (
            <OrderSummary
              order={orderData}
              type={orderTypes.find((status) => status.value === form.watch("orderType"))?.label || ''}
            />
          )}
          <div className="flex-1">
            <header className='flex flex-col gap-4 mb-8'>
              <div>
                <h1 className='font-bold text-3xl flex items-center gap-2'>
                  {!orderId && `New ${orderTypes.find((status) => status.value === form.watch("orderType"))?.label || ''}`}
                </h1>
              </div>
              <div className="flex items-center gap-4">
                <FormField
                  control={form.control}
                  name="orderCompany"
                  render={({ field }: any) => (
                    <FormItem>
                      <FormLabel>Company</FormLabel>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={field.value?.toString()}
                        disabled={!editMode}
                      >
                        <FormControl>
                          <SelectTrigger className="min-w-[150px] bg-space-700 border-space-500">
                            <SelectValue placeholder="Select a company" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent className="bg-space-700 border-0 w-auto">
                          {companiesAvailable?.map((company) => (
                            <SelectItem
                              key={company.id}
                              value={company.id.toString()}
                            >
                              {company.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="orderSalesRep"
                  render={({ field }: any) => (
                    <FormItem>
                      <FormLabel>Sales Representative</FormLabel>
                      <Select
                        onValueChange={field.onChange}
                        value={field.value.toString()}
                        disabled={!editMode}
                      >
                        <FormControl>
                          <SelectTrigger className="min-w-[150px] bg-space-700 border-space-500">
                            <SelectValue placeholder="Select someone" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent className="bg-space-700 border-0 w-auto">
                          {usersAvailable?.map((user) => (
                            <SelectItem
                              key={user.id}
                              value={user.id.toString()}
                            >
                              {user.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </header>

            <div className='flex items-end gap-4'>
              <div className="flex flex-flow gap-4">
                {orderId && (
                  <FormField
                    control={form.control}
                    name="orderQueue"
                    render={({ field }: any) => (
                      <FormItem>
                        <FormLabel>Order Queue</FormLabel>
                        <Select
                          value={field.value}
                          onValueChange={field.onChange}
                          disabled={!editMode}
                        >
                          <FormControl>
                            <SelectTrigger className="min-w-[150px] bg-space-700 border-space-500">
                              <SelectValue placeholder="Choose a queue" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent className="bg-space-700 border-0 w-auto">
                            {orderQueue.map(({ label, value }) => (
                              <SelectItem key={value} value={value}>
                                {label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                )}

                <FormField
                  control={form.control}
                  name="orderType"
                  render={({ field }: any) => (
                    <FormItem>
                      <FormLabel>Order Type</FormLabel>
                      <Select
                        value={field.value}
                        onValueChange={field.onChange}
                        disabled={!editMode}
                      >
                        <FormControl>
                          <SelectTrigger className="min-w-[150px] bg-space-700 border-space-500">
                            <SelectValue placeholder="Choose an order type" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent className="bg-space-700 border-0 w-auto">
                          {orderTypes.map(({ label, value }) => (
                            <SelectItem key={value} value={value}>
                              {label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="orderSubType"
                  render={({ field }: any) => (
                    <FormItem>
                      <FormLabel>Order Sub Type</FormLabel>
                      <Select
                        value={field.value}
                        onValueChange={field.onChange}
                        disabled={!editMode}
                      >
                        <FormControl>
                          <SelectTrigger className="min-w-[150px] bg-space-700 border-space-500">
                            <SelectValue placeholder="Choose a sub type" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent className="bg-space-700 border-0 w-auto">
                          {orderSubTypes.map(({ label, value }) => (
                            <SelectItem key={value} value={value}>
                              {label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="orderSource"
                  render={({ field }: any) => (
                    <FormItem>
                      <FormLabel>Order Source</FormLabel>
                      <Select
                        value={field.value}
                        onValueChange={field.onChange}
                        disabled={!editMode}
                      >
                        <FormControl>
                          <SelectTrigger className="min-w-[150px] bg-space-700 border-space-500">
                            <SelectValue placeholder="Choose a source" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent className="bg-space-700 border-0 w-auto">
                          {orderSource.map(({ label, value }) => (
                            <SelectItem key={value} value={value}>
                              {label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="ml-auto flex items-end gap-4">
                <FormField
                  control={form.control}
                  name="taxRate"
                  render={({ field }: any) => (
                    <FormItem>
                      <FormLabel>Tax Rate (%)</FormLabel>
                      <Input
                        type="number"
                        min="0"
                        max="100"
                        step="0.001"
                        {...field}
                        value={field.value || 0}
                        onChange={(e: any) => {
                          const value = e.target.value === '' ? 0 : parseFloat(e.target.value)
                          field.onChange(value)
                          calculateTotals()
                        }}
                        className="bg-space-700 border-space-500 w-24"
                        disabled={!editMode}
                      />
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="shippingCost"
                  render={({ field }: any) => (
                    <FormItem>
                      <FormLabel>Shipping</FormLabel>
                      <Input
                        type="number"
                        {...field}
                        value={field.value || 0}
                        onChange={(e: any) => {
                          const value = e.target.value === '' ? 0 : parseFloat(e.target.value)
                          field.onChange(value)
                          calculateTotals()
                        }}
                        className="bg-space-700 border-space-500 w-28"
                        disabled={true}
                      />
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {form.watch("orderType") !== 'draft' && (
                  <>
                    <FormField
                      control={form.control}
                      name="promiseDate"
                      render={({ field }: any) => (
                        <FormItem>
                          <FormLabel>Promise Date</FormLabel>
                          <Input
                            type="date"
                            className="bg-space-700 border-space-500"
                            disabled={!editMode}
                            {...field}
                            value={formatDateForInput(field.value)}
                            onChange={(e: any) => field.onChange(e.target.value)}
                          />
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="expireDate"
                      render={({ field }: any) => (
                        <FormItem>
                          <FormLabel>Expire Date</FormLabel>
                          <Input
                            type="date"
                            className="bg-space-700 border-space-500"
                            disabled={!editMode}
                            {...field}
                            value={formatDateForInput(field.value)}
                            onChange={(e: any) => field.onChange(e.target.value)}
                          />
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </>
                )}

                {orderId ? (
                  <DropdownMenu>
                    <DropdownMenuTrigger className='border border-space-800 rounded-md p-2 px-4'>
                      {isSubmitting ? (
                        <><Loader2 className="mr-2 h-4 w-4 animate-spin" />Please wait</>
                      ) : (
                        <>Quick Actions</>
                      )}
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      <DropdownMenuLabel>Order Functions</DropdownMenuLabel>
                      <DropdownMenuSeparator />
                      {!editMode ? (
                        <DropdownMenuItem onClick={() => setEditMode(true)}>
                          Edit {form.watch("orderType")}
                        </DropdownMenuItem>
                      ) : (
                        <DropdownMenuItem onClick={form.handleSubmit(onSubmit)}>
                          Update {form.watch("orderType")}
                        </DropdownMenuItem>
                      )}
                      {
                        (publicId && domain) && (
                          <DropdownMenuItem onClick={() => print2PDF(publicId, domain)}>
                            Print to PDF
                          </DropdownMenuItem>
                        )
                      }
                      <DropdownMenuItem onClick={() => syncToSC()}>
                        Sync to SellerCloud
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                ) : (
                  <Button
                    disabled={isSubmitting || fields.length <= 0 || !form.watch('customer')}
                    type="submit"
                    className={`${!form.watch('customer') ? 'bg-gray-500' : 'bg-green-500'} text-white font-bold`}
                  >
                    {isSubmitting ? (
                      <><Loader2 className="mr-2 h-4 w-4 animate-spin" />Please wait</>
                    ) : (
                      <>Create {orderTypes.find((status) => status.value === form.watch("orderType"))?.label}</>
                    )}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-4">
          <section className="flex items-start gap-4">
            <div className="flex-1 flex flex-col gap-4">
              {editMode && (
                <div className="flex items-center gap-4 relative">
                  <Input
                    ref={suggestionsInputRef}
                    type="text"
                    placeholder="Add products by SKU, Name, or Classification"
                    className="bg-space-800 border-space-700 text-white placeholder:text-space-600"
                    onChange={handleProductSearchInputChange}
                    autoComplete="off"
                  />
                  <CustomItem
                    onAddItem={(item) => addProductToOrder(item)}
                    disabled={!editMode}
                  />
                  {productSuggestions.length > 0 && (
                    <div
                      ref={suggestionsRef}
                      className="absolute top-8 left-0 bg-space-800 border border-space-900 overflow-hidden mt-4 py-4 px-2 w-full z-10 shadow-xl max-h-60 overflow-y-auto"
                    >
                      {productSuggestions.map((product, index) => (
                        <div
                          key={index}
                          onClick={() => addProductToOrder(product)}
                          className="cursor-pointer hover:bg-space-700 p-2 flex items-center space-x-2"
                        >
                          <div className="w-12 h-12 bg-black rounded flex items-center justify-center">
                            <img src={product.image} alt="Product" />
                          </div>
                          <div className="flex-1">
                            <p className="font-semibold">{product.title} - {product.sku}</p>
                            <p className="text-sm text-space-300">${product.price}</p>
                            <p className="text-sm text-space-300">Available: {product.available}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}

              <div className="bg-space-900 p-4 rounded-md">
                {fields.length > 0 ? renderOrderItems() : (
                  <div className="text-center font-bold uppercase text-space-600 p-8">
                    No items added yet
                  </div>
                )}
              </div>

              <Tabs defaultValue="notes" className="bg-space-900 rounded-md">
                <TabsList className="w-full justify-between bg-space-800">
                  <TabsTrigger value="notes" className="w-full data-[state=active]:bg-space-900">Notes</TabsTrigger>
                  <TabsTrigger value="payments" className="w-full data-[state=active]:bg-space-900">Payments</TabsTrigger>
                  <TabsTrigger value="timeline" className="w-full data-[state=active]:bg-space-900">Timeline</TabsTrigger>
                  <TabsTrigger value="order_docs" className="w-full data-[state=active]:bg-space-900">Order Documents</TabsTrigger>
                  <TabsTrigger value="ship_rates" className="w-full data-[state=active]:bg-space-900">Shipping Rates</TabsTrigger>
                  <TabsTrigger value="po" className="w-full data-[state=active]:bg-space-900">Purchase Orders</TabsTrigger>
                  <TabsTrigger value="returns" className="w-full data-[state=active]:bg-space-900">Returns</TabsTrigger>
                  <TabsTrigger value="profit_loss" className="w-full data-[state=active]:bg-space-900">P&L</TabsTrigger>
                  <TabsTrigger value="inventory" className="w-full data-[state=active]:bg-space-900">Inventory</TabsTrigger>
                </TabsList>
                <TabsContent value="notes" className="p-4">
                  Notes have been temporarily disabled
                </TabsContent>
                <TabsContent value="payments" className="p-4">Payments coming soon</TabsContent>
                <TabsContent value="timeline" className="p-4">Timeline coming soon</TabsContent>
                <TabsContent value="order_docs" className="p-4">Documents coming soon</TabsContent>
                <TabsContent value="ship_rates" className="p-4">Shipping rates coming soon</TabsContent>
                <TabsContent value="po" className="p-4">Purchase orders coming soon</TabsContent>
                <TabsContent value="returns" className="p-4">Returns coming soon</TabsContent>
                <TabsContent value="profit_loss" className="p-4">P&L coming soon</TabsContent>
                <TabsContent value="inventory" className="p-4">Inventory coming soon</TabsContent>
              </Tabs>
            </div>

            {/* Right sidebar */}
            <aside className="flex flex-col gap-4 w-[350px]">
              <section className="w-full">
                <CustomerManagement
                  onCustomerSelect={(customer) => form.setValue('customer', customer)}
                  selectedCustomer={form.watch('customer')}
                  editMode={editMode}
                />
              </section>
              <section className="w-full rounded-md p-4 bg-space-900 ml-auto text-lg">
                <div className="flex flex-col">
                  <div className="flex items-center gap-2 justify-end">
                    <h4 className="text-space-400">Sub Total:</h4>
                    <div className="font-bold">${formatNumber(subTotal)}</div>
                  </div>
                  <div className="flex items-center gap-2 justify-end">
                    <h4 className="text-space-400">Tax ({form.watch('taxRate') || 0}%):</h4>
                    <div className="font-bold">${formatNumber(taxes)}</div>
                  </div>
                  <div className="flex items-center gap-2 justify-end">
                    <h4 className="text-space-400">Total:</h4>
                    <div className="font-bold">${formatNumber(total)}</div>
                  </div>
                  <hr className="mt-2 mb-1 border-space-700" />
                  <div className="flex flex-col items-end gap-2">
                    <div className="text-right">
                      <h4 className="text-space-400">Estimated Profit:</h4>
                      <div>
                        ${formatNumber(profit)}
                        {profitPercentage > 0 && (
                          <span className="pl-1">({profitPercentage.toFixed(2)}%)</span>
                        )}
                      </div>
                    </div>
                    <div className={`font-bold ${marginPassed ? 'bg-green-500' : 'bg-red-700'} rounded px-2 py-1 text-sm w-full text-center uppercase`}>
                      {marginPassed ? 'Sale Margin Has Passed' : 'Sale Margin Not Passed'}
                    </div>
                  </div>
                </div>
              </section>
            </aside>
          </section>
        </div>
      </form>

      <AlertDialog open={itemToRemove !== null}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Remove Item</AlertDialogTitle>
            <AlertDialogDescription>
              The quantity has been set to 0. Do you want to remove this item from the order?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => {
              if (itemToRemove) {
                update(itemToRemove.index, { ...fields[itemToRemove.index], quantity: 1 })
                setItemToRemove(null)
              }
            }}>
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction onClick={() => {
              if (itemToRemove) {
                removeProductFromOrder(itemToRemove.index)
                setItemToRemove(null)
              }
            }}>
              Confirm
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Form>
  )
}

export default OrderView