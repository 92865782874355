// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "flowbite/dist/flowbite.turbo.js"
import "@fortawesome/fontawesome-free/js/all"
import "trix"
import "@rails/activestorage"
import "chartkick/chart.js"
//import "./entrypoints"
import "./controllers"
import "./react"

document.addEventListener('turbo:load', () => {
  document.cookie = `timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`
})
import "./channels"
