import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["subtotal", "tax", "total"]

  connect() {
    this.updateTotals()
  }

  updateTotals() {
    let subtotal = 0
    document.querySelectorAll('[data-order-item-target="total"]').forEach(el => {
      const amount = parseFloat(el.textContent.replace(/[^0-9.-]+/g, ""))
      if (!isNaN(amount)) {
        subtotal += amount
      }
    })

    const taxRate = parseFloat(document.querySelector('input[name$="[tax_rate]"]')?.value || "0")
    const tax = subtotal * (taxRate / 100)
    const total = subtotal + tax

    this.subtotalTarget.textContent = this.formatCurrency(subtotal)
    this.taxTarget.textContent = this.formatCurrency(tax)
    this.totalTarget.textContent = this.formatCurrency(total)
  }

  formatCurrency(amount) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount)
  }
}