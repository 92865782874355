import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "results"]

  connect() {
    console.log("ProductSearch controller connected")
  }

  search() {
    clearTimeout(this.timeout)

    this.timeout = setTimeout(async () => {
      const query = this.inputTarget.value.trim()
      if (query.length < 2) {
        this.resultsTarget.innerHTML = ''
        return
      }

      try {
        const baseUrl = window.location.hostname === 'localhost' ?
          'http://localhost:3000' : 'https://onyx.ktappliance.com'

        const response = await fetch(`${baseUrl}/api/v1/products/search?query=${encodeURIComponent(query)}`)
        const data = await response.json()

        if (data.results?.length > 0) {
          const resultsHtml = this.buildResultsHtml(data.results)
          this.resultsTarget.innerHTML = resultsHtml
        } else {
          this.resultsTarget.innerHTML = `
            <div class="bg-space-900 rounded-md shadow-lg border border-space-800 p-8 text-center text-space-400">
              <p>No products found</p>
            </div>`
        }
      } catch (error) {
        console.error('Search failed:', error)
        this.resultsTarget.innerHTML = `
          <div class="bg-space-900 rounded-md shadow-lg border border-space-800 p-8 text-center text-red-500">
            <p>Error performing search</p>
          </div>`
      }
    }, 300)
  }

  buildResultsHtml(products) {
    return `
      <div class="bg-space-900 rounded-md shadow-lg border border-space-800 max-h-96 overflow-auto">
        <ul class="divide-y divide-space-800">
          ${products.map(product => {
      if (!product.cost) {
        console.warn(`Product ${product.sku} is missing cost information`)
      }

      const escapedSku = (product.sku || '').replace(/"/g, '&quot;')
      const escapedTitle = (product.title || '').replace(/"/g, '&quot;')
      const escapedBrand = (product.brand || '').replace(/"/g, '&quot;')
      const escapedImage = (product.image || '').replace(/"/g, '&quot;')

      const stockCount = product.stock?.reduce((sum, s) => sum + (s.available || 0), 0) || 0

      return `
              <li class="p-4 hover:bg-space-800 cursor-pointer" 
                  data-action="click->product-search#selectProduct"
                  data-product-id="${product.id}"
                  data-product-sku="${escapedSku}"
                  data-product-title="${escapedTitle}"
                  data-product-brand="${escapedBrand}"
                  data-product-price="${product.price || 0}"
                  data-product-cost="${product.cost || 0}"
                  data-product-image="${escapedImage}">
                <div class="flex items-center gap-4">
                  ${product.image ?
          `<img src="${product.image}" class="w-16 h-16 object-cover rounded bg-space-800" alt="${escapedTitle}" />` :
          `<div class="w-16 h-16 bg-space-800 rounded flex-shrink-0"></div>`
        }
                  <div class="flex-1 min-w-0">
                    <div class="font-bold truncate">${escapedTitle}</div>
                    <div class="text-sm text-space-400">SKU: ${escapedSku}</div>
                    <div class="text-sm text-space-400">${escapedBrand}</div>
                  </div>
                  <div class="text-right">
                    <div class="font-bold">${this.formatCurrency(product.price)}</div>
                    <div class="text-sm text-space-400">Stock: ${stockCount}</div>
                  </div>
                </div>
              </li>
            `
    }).join('')}
        </ul>
      </div>
    `
  }

  selectProduct(e) {
    const item = e.currentTarget
    const productData = {
      productId: item.dataset.productId,
      productSku: item.dataset.productSku,
      productTitle: item.dataset.productTitle,
      productPrice: parseFloat(item.dataset.productPrice),
      productCost: parseFloat(item.dataset.productCost),
      productImage: item.dataset.productImage
    }

    if (!productData.productCost) {
      console.warn(`Selected product ${productData.productSku} has no cost information`)
    }

    console.log("Selected product:", productData)

    const template = document.querySelector('[data-nested-form-target="template"]')
    if (!template) {
      console.error("Template not found")
      return
    }

    const content = template.content.cloneNode(true)
    const wrapper = content.querySelector('.order-item-wrapper')
    const newIndex = new Date().getTime()

    if (wrapper) {
      // Update input names with new index
      wrapper.querySelectorAll('input').forEach(input => {
        input.name = input.name.replace('NEW_RECORD', newIndex)
      })

      // Set all form fields
      const costField = wrapper.querySelector('[data-order-item-target="cost"]')
      const costDisplay = wrapper.querySelector('[data-order-item-target="costDisplay"]')
      const skuField = wrapper.querySelector('input[name$="[sku]"]')
      const infoButton = wrapper.querySelector('[data-action="order-item#showProductInfo"]')

      if (costField) costField.value = productData.productCost
      if (costDisplay) costDisplay.value = productData.productCost
      if (skuField) skuField.value = productData.productSku
      if (infoButton) infoButton.dataset.sku = productData.productSku

      wrapper.querySelector('input[name$="[product_variant_id]"]').value = productData.productId
      wrapper.querySelector('input[name$="[price]"]').value = productData.productPrice
      wrapper.querySelector('input[name$="[quantity]"]').value = 1

      // Set display elements
      wrapper.querySelector('.product-title').textContent = productData.productTitle
      wrapper.querySelector('.product-sku').textContent = `SKU: ${productData.productSku}`

      if (productData.productImage) {
        const imgContainer = wrapper.querySelector('.w-24.h-24')
        if (imgContainer) {
          imgContainer.innerHTML = `<img src="${productData.productImage}" class="w-full h-full object-cover rounded-lg" alt="${productData.productTitle}" />`
        }
      }

      // Add to form
      const targetContainer = document.querySelector('[data-nested-form-target="target"]')
      if (targetContainer) {
        targetContainer.appendChild(wrapper)

        // Remove empty state if exists
        document.querySelector('[data-nested-form-target="empty"]')?.remove()

        // Trigger update
        wrapper.dispatchEvent(new CustomEvent('order-item:update', {
          bubbles: true,
          detail: {
            total: productData.productPrice,
            cost: productData.productCost,
            quantity: 1
          }
        }))
      }
    }

    // Clear search
    this.inputTarget.value = ''
    this.resultsTarget.innerHTML = ''
  }

  formatCurrency(amount) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount)
  }

  disconnect() {
    clearTimeout(this.timeout)
  }
}